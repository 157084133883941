
import { Component, Vue } from "vue-property-decorator";
import { PropType } from "vue";
import { Defaults } from "@/helpers/Defaults";
import { IInstruction } from "@/types/Instruction";
import { ICommodity } from "@/types/Commodity";
import { CommodityService } from "@/services/commodity-service";
import { InstructionService } from "@/services/instruction-service";
import html2pdf from "html2pdf.js";
const InstructionProps = Vue.extend({
  props: {
    instruction: Object,
    commodities: Array as PropType<ICommodity[]>,
  },
});
@Component({
  name: "InstructionPDF",
  components: {},
})
export default class instructionPDF extends InstructionProps {
  defaults = new Defaults();
  instruction: IInstruction = this.defaults.instruction;
  instructionService = new InstructionService();
  commodityService = new CommodityService();
  commodities: ICommodity[] = [];
  commodity: ICommodity = this.defaults.commodity;
  getCommodityById(id: number): ICommodity {
    const commodities: ICommodity[] = this.commodities;
    const found = commodities.find((element) => element.id == id);
    if (found != undefined) return found || this.defaults.commodity;
    return this.defaults.commodity;
  }
  async initialize(): Promise<void> {
    this.commodities = await this.commodityService.getAll();
    let id = this.$route.params.id;
    let instructionResponse = await this.instructionService.get(parseInt(id));
    this.instruction = instructionResponse.data;
  }
  async created(): Promise<void> {
    await this.initialize();
  }
  mounted() {
    html2pdf(this.$refs.document, {
      margin: 1,
      filename: "document.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { dpi: 192, letterRendering: true },
      jsPDF: { unit: "in", format: "letter", orientation: "landscape" },
    });
  }
}
